import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../../components/seo";
import Layout from "../../containers/layout/layout";
import Header from "../../containers/layout/header/header-one";
import Footer from "../../containers/layout/footer/footer-one";
import PageHeader from "../../components/pageheader";
import { Container, Row, Col } from "../../components/ui/wrapper";
import Heading from "../../components/ui/heading";
import Sidebar from "../../containers/blog/sidebar";
import CTA from "../../containers/global/cta-area/section-one";
import Blog from "../../components/blog/layout-two";
import {
  BlogListWrapper,
  BlogBoxWrapper,
  BlogBox,
  SearchInfoHeader,
  SearchError,
} from "./search-template.style";

const SearchTemplate = ({ data, pageContext, location, ...restProps }) => {
  const { headingStyle } = restProps;
  let blogs = data.posts.posts.edges || [];
  const { state } = location;
  const query = state && state.query.toLowerCase();
  let filteredBlogs = [];
  if (state) {
    filteredBlogs = blogs.filter((post) => {
      const {
        title,
        categories,
        tags,
        excerpt,
        author: {node : { name: authorName }},
      } = post.node;
      return (
        (title && title.toLowerCase().includes(query)) ||
        (excerpt && excerpt.toLowerCase().includes(query)) ||
        (authorName && authorName.toLowerCase().includes(query)) ||
        (tags && tags.edges.length &&
          tags.edges.find(el => el.node.name.includes(query.toLowerCase()))) ||
        (categories && categories.edges.length &&
          categories.edges.find(el => el.node.name.includes(query.toLowerCase())))
      );
    });
  }

  return (
    <Layout location={location}>
      <SEO title={`Search`} />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title={`Search`}
      />
      <main className="site-wrapper-reveal">
        <BlogListWrapper>
          <Container>
            <Row>
              <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
                <Sidebar />
              </Col>
              <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
                {filteredBlogs && (
                  <SearchInfoHeader>
                    <Heading {...headingStyle}>
                      Search for: <span>{query}</span>
                    </Heading>
                    <Heading as="h5">
                      Posts Found: {filteredBlogs.length}
                    </Heading>
                  </SearchInfoHeader>
                )}
                {filteredBlogs.length > 0 && (
                  <BlogBoxWrapper>
                    {filteredBlogs.map((blog) => (
                      <BlogBox key={blog.node.slug}>
                        <Blog content={blog.node} />
                      </BlogBox>
                    ))}
                  </BlogBoxWrapper>
                )}
                {filteredBlogs.length === 0 && (
                  <SearchError>
                    <h2>Nothing Found</h2>
                  </SearchError>
                )}
              </Col>
            </Row>
          </Container>
        </BlogListWrapper>
        <CTA />
      </main>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query BlogBySearchQuery {  
          posts : wpcontent {
            posts (first: 10000) {
              edges {
                node {
                  id      
                  postId
                  excerpt
                  featuredImage {
                    node {
                      id
                      mediaDetails {
                        file
                      }
                      sourceUrl
                      imageFile {
                        childImageSharp {
                          fluid(maxWidth: 1170, maxHeight: 570, quality: 100) {
                            presentationWidth
                            presentationHeight
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  title(format: RENDERED)
                  author {
                    node {
                      id
                      name
                      avatar {
                        url
                      }
                    }
                  }
                  slug
                  date
                  tags {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                  categories {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                }
              }  
            }
          }
        }
    `;

SearchTemplate.propTypes = {
  headingStyle: PropTypes.object,
};

SearchTemplate.defaultProps = {
  headingStyle: {
    as: "h3",
    child: {
      color: "primary",
    },
  },
};
export default SearchTemplate;
